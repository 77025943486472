import { colorMatrix } from "../../../maps/components/MapComponent/constants";

export const getCircleColor = (
  variety: string,
  rate: number,
  trialProducts: any,
  trialMeasureUnit?: any,
  ratesDosageList?: any
): string => {
  if (!trialProducts || !variety || !rate) return "";

  const idx = trialProducts.map((x: any) => x.variety).indexOf(variety) % 10;
  const variantRates =
    ratesDosageList?.filter((item: any) => item.variety === variety) || [];

  if (variantRates.length === 0) return "";
  if (variantRates.length === 1) return colorMatrix[idx][50];

  const variantAverage =
    variantRates.reduce(
      (acc: number, item: any) => (item.rate as number) + acc,
      0
    ) / variantRates.length;
  const closestToAverage = variantRates.reduce((prev: any, curr: any) =>
    Math.abs(curr.rate - variantAverage) < Math.abs(prev.rate - variantAverage)
      ? curr
      : prev
  );

  if (rate === closestToAverage.rate) return colorMatrix[idx][50];

  const lowerThanAvg = variantRates
    .filter(
      (item: any) =>
        item.rate < variantAverage && item.rate !== closestToAverage.rate
    )
    .map((item: any) => item.rate)
    .sort((a: number, b: number) => b - a);
  const higherThanAvg = variantRates
    .filter(
      (item: any) =>
        item.rate > variantAverage && item.rate !== closestToAverage.rate
    )
    .map((item: any) => item.rate)
    .sort((a: number, b: number) => a - b);

  if (rate < variantAverage) {
    const position = lowerThanAvg.indexOf(rate);
    if (position === 0) return colorMatrix[idx][40];
    if (position === 1) return colorMatrix[idx][30];
    return colorMatrix[idx][20];
  } else {
    const position = higherThanAvg.indexOf(rate);
    if (position === 0) return colorMatrix[idx][60];
    if (position === 1) return colorMatrix[idx][70];
    if (position === 2) return colorMatrix[idx][80];
    return colorMatrix[idx][90];
  }
};
