export const transformProductList = (productList: any): any => {
  productList.sort((a: any, b: any) =>
    a?.commercial_name.localeCompare(b?.commercial_name)
  );
  return productList?.map((product: any, index: number) => {
    return {
      key: index + 1,
      variety: product?.commercial_name || " ",
      manufacturer: product?.manufacturer || " ",
      measure_unit: " ",
      id: product?.id,
    };
  });
};
