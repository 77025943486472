import { Dropdown, Select } from "antd";
import { DeckOfApps } from "@cw-elements/deck-of-apps";
import { type ReactNode, type FC, type ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { Avatar, TopNavBar } from "syngenta-digital-cropwise-react-ui-kit";
import { type profile } from "./types";
import "./header.less";
import Logo from "../../assets/images/trialing-logo.svg";
import { useAuth } from "../../lib/auth/AuthContext";
import { OrganizationSelect } from "./headerSelectBox/organizationSelect";
import { FarmSelect } from "./headerSelectBox/farmSelect";
import { SupportedLanguages } from "../../constants/supportedLanguages";
import i18n from "../../i18n";
import { basepath, route } from "../../pages/constant";

export const getInitials = (name: string): string => {
  try {
    const firstName = name.split(" ")[0];
    const lastName = name.length > 0 ? name.split(" ")[1] : " ";
    return `${firstName.split("")[0]}${lastName.split("")[0]}`.toUpperCase();
  } catch {
    return "NA";
  }
};

const ProfileOverlay = (): JSX.Element => {
  const { user, logout, setLogoutStatus } = useAuth();
  const language = i18n.language;
  const changeLanguage = (language: string): void => {
    i18n.changeLanguage(language).catch((error) => {
      console.error("Error", error);
    });
  };

  return (
    <div data-cy="profileOverlay" className="logoutOverlayBlock">
      <div className="profileInfo">
        <h3>{"User Profile"}</h3>
        <div className="profileUserData">
          <span>{user?.name}</span>
        </div>
      </div>
      <div className="profilePreferencesSection">
        <span className="profilePreferences">Preferences</span>
      </div>
      <div className="profileLanguageSection">
        <span>Language</span>
        <Select
          defaultValue={language}
          style={{ width: 160 }}
          onChange={changeLanguage}
          options={SupportedLanguages}
        />
      </div>
      <div
        className="logoutLink"
        onClick={() => {
          setLogoutStatus(true);
          void logout();
        }}
        role="button"
      >
        <span>{"Log out"}</span>
      </div>
    </div>
  );
};

const Profile: FC<profile> = ({ initials }) => {
  return (
    <Dropdown
      trigger={["click"]}
      overlay={<ProfileOverlay />}
      placement="topRight"
    >
      <a
        data-testid="profile-btn"
        data-cy="profile-icon"
        className="ant-dropdown-link"
        onClick={(e) => {
          e.preventDefault();
        }}
        role="button"
      >
        <Avatar shape="circle" size={40}>
          {initials}
        </Avatar>
      </a>
    </Dropdown>
  );
};

function GetNavBarIcon(): ReactNode {
  return (
    <div data-cy="navBarIcon" style={{ display: "flex", alignItems: "center" }}>
      <img src={Logo} alt="Cropwise Sustainability" />
    </div>
  );
}

const NavbarRightSection = (): JSX.Element => {
  return (
    <div className="navbar-right-sec">
      <div className="divider" />
    </div>
  );
};

export const GlobalHeader: FC = (): ReactElement => {
  const { user } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <TopNavBar
      deckOfApps={
        <div style={{ display: "flex", alignItems: "center" }}>
          <DeckOfApps
            loadingDataFromApp={false}
            workspaceId={user?.default_licensing_account_id}
            onLogout={() => () => { }}
          />
        </div>
      }
      logo={GetNavBarIcon()}
      newDesign={true}
      navbarUserInitials="RC"
      leftSection={
        <div className="select-properties">
          {currentPath !== `${basepath}/${route.ORGS.path}` && (
            <OrganizationSelect />
          )}
          <FarmSelect />
        </div>
      }
      rightSection={<NavbarRightSection />}
      userProfile={
        <Profile initials={getInitials(user?.name)} />
        // <ProfileDropdown
        //   availableLanguages={SupportedLanguages.map((v) => v.key) as any[]}
        //   availableUnitSystems={[]}
        //   onLogout={async () => { await logout() }}
        //   onLanguageChange={changeLanguage}
        // />
      }
    />
  );
};
