import { type FC } from "react";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { Tooltip } from "antd";
import { type Props } from "../types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import "./editButton.less";
import editIcon from "../../../assets/images/edit-hover.svg";
import editDiseableIcon from "../../../assets/images/edit.svg";

export const EditButton: FC<Props> = ({ onClick, disabled, children }) => {
  const { t } = useTranslation();
  let tootTipText;
  disabled === true
    ? (tootTipText = (
        <span>
          {formatTranslation(t("protocols.editDisableButtonTooltipText"))}
        </span>
      ))
    : (tootTipText = (
        <span>{formatTranslation(t("protocols.editButtonTooltipText"))}</span>
      ));

  return (
    <Tooltip placement="left" title={tootTipText}>
      <Button
        className={disabled ? "edit-button-disabled" : "edit-button"}
        data-testid="edit-button"
        onClick={onClick}
        disabled={disabled}
      >
        {disabled ? (
          <img src={editDiseableIcon} alt="edit-icon-disabled" />
        ) : (
          <img src={editIcon} alt="edit-icon" />
        )}
        {children}
      </Button>
    </Tooltip>
  );
};
