import { useEffect, useState, type FC } from "react";
import { SecondaryButton } from "../SecondaryButton";
import "./PlotCard.less";
import { type PlotCardProps } from "../../../pages/new-trail/types";
import { BufferZonePopup } from "./popUp/bufferZonePopup";
import { TrialPlotsPopup } from "./popUp/trialPopup";
import { usePlotCardState } from "./utils";
import { RenderCards } from "./renderCards";
import { DeletePlotModal } from "../../../pages/new-trail/deletePlotModal";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import { useParams } from "react-router-dom";

export const PlotCard: FC<PlotCardProps> = ({ data, trialData }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    contextHolder,
    handleAddData,
    toggleVisibility,
    handleBufferZoneData,
    handleTrailPlotsData,
    deleteData,
    setDeleteData,
    handleDeleteData,
  } = usePlotCardState();

  const { newTrialState, setNewTrialState } = useNewTrialState();
  const { id } = useParams();

  useEffect(() => {
    const gridData: any = {
      id: new Date().getTime(),
      name: "Machinery Grid",
      key: "Machinery Grid",
      width: 50,
      rotation: 0,
      visible: true,
    };
    setNewTrialState((prevState: any) => ({
      ...prevState,
      machinery_grid: [gridData],
    }));
  }, []);

  useEffect(() => {
    if (id && trialData?.trialData?.machinery_grid) {
      const machineryGridData = {
        ...trialData.trialData.machinery_grid,
        key: "Machinery Grid",
        name: "Machinery Grid",
        visible: true,
        id: parseInt("171923438714"),
      };

      setNewTrialState((prevState: any) => ({
        ...prevState,
        machinery_grid: [machineryGridData],
      }));
    }
    if (id && trialData?.trialData?.buffer_zone) {
      const bufferZoneData = {
        ...trialData.trialData.buffer_zone,
        key: "Buffer Zone",
        name: "Buffer Zone",
        visible: true,
        id: parseInt("171923438713"),
      };

      setNewTrialState((prevState: any) => ({
        ...prevState,
        buffer_zone: [bufferZoneData],
      }));
    }
    if (id && Array.isArray(trialData?.trialData?.ab_line)) {
      const abLineData = trialData.trialData.ab_line.map(
        (item: any, index: number) => ({
          ...item,
          key: "A/B Line + Machinery Grid",
          visible: true,
          id: parseInt(`171923438715${index + 1}`),
        })
      );

      setNewTrialState((prevState: any) => ({
        ...prevState,
        ab_line: abLineData,
      }));
    }

    if (id && Array.isArray(trialData?.trialData?.trial_plots)) {
      const trialPlotData = trialData.trialData.trial_plots.map(
        (item: any, index: number) => {
          const updatedReplicants =
            item.replicants?.map((replicant: any, repIndex: number) => ({
              ...replicant,
              id: parseInt(`171923438712${index + 1}${repIndex + 1}`),
              visible: true,
              key: "trial_plots",
              name: `Replicant ${repIndex + 1}`,
            })) || [];

          return {
            ...item,
            plot: {
              ...item.plot,
              key: "Trial Plots",
              visible: true,
              id: parseInt(`171923438712${index + 1}`),
            },
            replicants: updatedReplicants,
          };
        }
      );

      setNewTrialState((prevState: any) => ({
        ...prevState,
        trial_plots: trialPlotData,
      }));
    }
  }, [id]);

  const cardTypes = [
    { cards: newTrialState?.ab_line },
    { cards: newTrialState?.machinery_grid },
    { cards: newTrialState?.buffer_zone },
    { cards: newTrialState?.trial_plots },
  ];

  return (
    <>
      {contextHolder}
      <div className="plot-card-main" data-testid="plot-card" key={data.id}>
        <div className="head-sec">
          {data.name !== "Machinery Grid" && (
            <div className="title-sec">
              <img src={data?.icon} alt="plot-icon" />
              <div>
                <h3 className="card-title">{data?.name}</h3>
                <p className="card-description">{data?.description}</p>
              </div>
            </div>
          )}

          {data.name === "A/B Line + Machinery Grid" && (
            <Button
              className="plus-btn"
              onClick={() => {
                handleAddData(data);
              }}
            >
              <SecondaryButton>+</SecondaryButton>
            </Button>
          )}

          {data.name === "Buffer Zone" && (
            <BufferZonePopup
              onHandleData={handleBufferZoneData}
              openPopoverKey="openPopover"
              val={data.id}
              trialData={trialData}
            >
              <Button
                className={`plus-btn ${
                  newTrialState.buffer_zone?.length >= 1 ? "disabled" : ""
                }`}
                disabled={newTrialState.buffer_zone?.length >= 1}
              >
                <SecondaryButton>+</SecondaryButton>
              </Button>
            </BufferZonePopup>
          )}

          {/* {data.name === "Machinery Grid" && (
            <MachineryGridPopup
              openPopoverKey="openPopover"
              onHandleData={handleMachineGridData}
              val={data.id}
              trialData={trialData}
            >
              <Button
                className={`plus-btn ${
                  newTrialState.machinery_grid?.length >= 1 ? "disabled" : ""
                }`}
                disabled={newTrialState.machinery_grid?.length >= 1}
              >
                <SecondaryButton>+</SecondaryButton>
              </Button>
            </MachineryGridPopup>
          )} */}

          {data.name === "Trial Plots" && (
            <TrialPlotsPopup
              openPopoverKey="openPopover"
              onHandleData={handleTrailPlotsData}
              val={data.id}
            >
              <div className="plus-btn">
                <SecondaryButton>+</SecondaryButton>
              </div>
            </TrialPlotsPopup>
          )}
        </div>

        {cardTypes?.map(
          ({ cards }, i) =>
            cards?.length > 0 && (
              <RenderCards
                key={`${data.key as string}-${i}`}
                cards={cards}
                toggleVisibility={toggleVisibility}
                data={data}
                setIsModalOpen={setIsModalOpen}
                setDeleteData={setDeleteData}
                trialData={trialData}
              />
            )
        )}
      </div>
      <DeletePlotModal
        dataKey={data.key}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={deleteData}
        onDelete={handleDeleteData}
      />
    </>
  );
};
