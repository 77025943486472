import { type ReactElement } from "react";
import type { ProductCardProps } from "../interfaces/product-interfaces";
import { Flex, Typography } from "antd";
import { DeleteButton } from "../../../../shared/layout/DeleteButton";
import { DensityRangeComponent } from "./DensityRangeComponent";

export const ProductCard = ({
  globalDensityRange,
  globalDensityValues,
  variety,
  manufacturer,
  productKey,
  id,
  onDeleteProduct,
  updateProductDensityRange,
  productDensityRangeMin,
  productDensityRangeMax,
}: ProductCardProps): ReactElement => {
  return (
    <div className="product-card">
      <Flex vertical={false} justify="space-between" gap={10}>
        <div>
          <Typography>
            <strong>{variety}</strong>
          </Typography>
          <Typography>{manufacturer}</Typography>
        </div>
        {!globalDensityRange ? (
          <DensityRangeComponent
            globalDensityRange={globalDensityRange}
            productKey={productKey}
            updateProductDensityRange={updateProductDensityRange}
            productDensityRangeMin={productDensityRangeMin}
            productDensityRangeMax={productDensityRangeMax}
            productId={id}
          />
        ) : (
          <div>
            {globalDensityValues?.min ?? productDensityRangeMin} min /{" "}
            {globalDensityValues?.max ?? productDensityRangeMax} max
          </div>
        )}
        <Flex align="center">
          <DeleteButton
            protocolName={variety}
            onClick={() => {
              onDeleteProduct(productKey);
            }}
          ></DeleteButton>
        </Flex>
      </Flex>
    </div>
  );
};
