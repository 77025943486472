import { useEffect, useState, type ReactElement } from "react";
import { Button, Drawer, Flex, Form, Input, List, Select } from "antd";
import { targets } from "../constants";
import dragIcon from "../../../../src/assets/images/drag-icon.svg";
import { EditButton } from "../../../shared/layout/EditButton";

import { useCreateProtocolForm } from "..";
import { DeleteButton } from "../../../shared/layout/DeleteButton";
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DraggableStateSnapshot,
  type DraggableProvided,
  type DroppableProvided,
} from "@hello-pangea/dnd";
import emptyObj from "../../../../src/assets/images/map-fill.svg";
import { useSearchParams } from "react-router-dom";
import AxiosInstance from "../../../shared/utils/axios";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";

interface objectiveObjectType {
  name: string;
  description: string;
  targets: string[];
  notes?: string;
}
const { TextArea } = Input;
const { Option } = Select;

const ObjectivesComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { form, formValidationData, setFormValidationData } =
    useCreateProtocolForm();
  const current = form.getFieldValue("objectives") || [];

  if (!current) {
    form.setFieldValue("objectives", []);
  }

  const [newObjectiveForm] = Form.useForm<objectiveObjectType>();

  const [objectivesData, setObjectivesData] =
    useState<objectiveObjectType[]>(current);

  useEffect(() => {
    setObjectivesData(current);
  }, [form.getFieldValue("objectives")]);
  const [searchParams] = useSearchParams();
  const protocolId = searchParams.get("protocol_id");

  const [existingData, setExistingData] = useState<any>(null);
  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/protocol/${protocolId as string}`
      );
      setExistingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (protocolId) {
      getProtocolData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [protocolId]);

  useEffect(() => {
    if (existingData) {
      form.setFieldsValue({
        objectives: existingData.objectives,
      });
      // setTrialInfo(trialData);
      setFormValidationData({
        ...formValidationData,
        objectives: existingData.objectives,
      });
    }
  }, [existingData]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditingIndex, setIsEditingIndex] = useState<number | null>(null);
  const showDrawer = (): void => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = (): void => {
    setIsEditingIndex(null);
    setIsDrawerOpen(false);
    clearObjectivesForm();
  };

  const clearObjectivesForm = (): void => {
    newObjectiveForm.resetFields();
  };

  const handleCancel = (): void => {
    closeDrawer();
  };

  const handleAddObjective = (): void => {
    const newObjective = {
      name: newObjectiveForm.getFieldValue("name"),
      description: newObjectiveForm.getFieldValue("description"),
      targets: newObjectiveForm.getFieldValue("targets"),
      notes: "",
    };
    if (!form.getFieldsValue(true).objectives) {
      form.setFieldsValue({ objectives: [] });
    }
    if (newObjective.name === undefined || newObjective.name === "") {
      console.error("objective name cannot be empty");
      return;
    }
    if (current) {
      if (!isEditingIndex) {
        current.push(newObjective);
        form.setFieldsValue({
          objectives: current,
        });
      } else {
        current[isEditingIndex] = newObjective;
        form.setFieldsValue({
          objectives: current,
        });
      }
    }

    setFormValidationData({ ...formValidationData, objectives: current });
    closeDrawer();
  };

  const handleObjectiveDelete = (indexToDelete: number): void => {
    const currentList = current;

    const deleted = currentList.toSpliced(indexToDelete, 1);
    form.setFieldsValue({ objectives: deleted });
    // setObjectivesData(deleted);
    setFormValidationData({ ...formValidationData, objectives: deleted });
  };

  const handelObjectiveEdit = (indexToEdit: number): void => {
    const currentList = current;
    const itemToEdit = currentList[indexToEdit];
    newObjectiveForm.setFieldsValue({
      name: itemToEdit.name,
      description: itemToEdit.description,
      targets: itemToEdit.targets,
    });
    setIsEditingIndex(indexToEdit);
    showDrawer();
  };

  function handleOnDragEnd(result: any): void {
    if (!result.destination) return;

    const clonedListItems = JSON.parse(JSON.stringify(objectivesData));
    const [reorderedItem] = clonedListItems.splice(result.source.index, 1);

    clonedListItems.splice(result.destination.index, 0, reorderedItem);

    form.setFieldsValue({
      objectives: clonedListItems,
    });
    setObjectivesData(clonedListItems);
  }

  const [submittable, setSubmittable] = useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], newObjectiveForm);

  const validate = (): void => {
    newObjectiveForm
      .validateFields({ validateOnly: true, dirty: true })
      .then((x) => {
        setSubmittable(true);
      })
      .catch((y) => {
        setSubmittable(false);
      });
  };

  useEffect(() => {
    validate();
  }, [newObjectiveForm, values]);

  const renderObjectiveItem = (
    item: objectiveObjectType,
    index: number,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ): ReactElement => (
    <div ref={provided.innerRef} {...provided.draggableProps}>
      <List.Item
        className={`objective-list-item ${
          snapshot.isDragging ? "objective-item-dragging" : ""
        }`}
      >
        <div className="objective-item-icon">
          <img src={dragIcon} alt="dragIcon" {...provided.dragHandleProps} />
        </div>
        <div className="objective-item-name">{item?.name}</div>
        <div className="objective-item-target">
          <strong>
            {formatTranslation(
              t("createProtocol.objectives.objectiveItemTarget")
            )}
            :{" "}
          </strong>
          {item?.targets.map((target, index) =>
            index === item?.targets?.length - 1 ? target : target + ", "
          )}
        </div>
        <div className="objective-item-description">
          <strong>
            {formatTranslation(
              t("createProtocol.objectives.objectiveItemDescription")
            )}
            :{" "}
          </strong>
          {item?.description}
        </div>
        <div className="objective-item-actions">
          <Flex vertical={false} align="center" gap={8}>
            <EditButton
              onClick={() => {
                handelObjectiveEdit(index);
              }}
            />
            <DeleteButton
              onClick={() => {
                handleObjectiveDelete(index);
              }}
            />
          </Flex>
        </div>
      </List.Item>
    </div>
  );

  const renderDroppable = (provided: DroppableProvided): ReactElement => (
    <div ref={provided.innerRef} {...provided.droppableProps}>
      <List
        locale={{ emptyText: <ListEmptyText /> }}
        className={"list-container "}
        size="large"
        dataSource={objectivesData ?? []}
        rowKey="name"
        renderItem={(item, index) => (
          <Draggable
            draggableId={`draggable-${index}`}
            index={index}
            key={`draggable-${index}`}
          >
            {(provided, snapshot) =>
              renderObjectiveItem(item, index, provided, snapshot)
            }
          </Draggable>
        )}
      />
      {provided.placeholder}
    </div>
  );

  return (
    <div className="objectives-component">
      {/*  className : ant-drawer-body */}
      <Drawer
        className="objective-drawer"
        rootClassName="objective-drawer"
        title={formatTranslation(
          t("createProtocol.objectives.objectiveDrawerTitle")
        )}
        onClose={closeDrawer}
        open={isDrawerOpen}
      >
        <Form
          form={newObjectiveForm}
          name="objectives-form"
          initialValues={{ name: "", description: "", targets: [] }}
          layout="vertical"
          // Form state management happens here
          onValuesChange={(eventValue) => {
            // eventValue = {fieldName:value}
            const [fieldName, fieldValue] = Object.entries(eventValue)[0]; // [0] access is okay as only 1 object will be processed by event
            newObjectiveForm.setFieldValue(fieldName, fieldValue);
          }}
        >
          <div className="basicInfo-form-section">
            <Form.Item
              name="name"
              key="name"
              label={formatTranslation(
                t("createProtocol.objectives.objectiveName")
              )}
              rules={[{ required: true }]}
            >
              <Input></Input>
            </Form.Item>
          </div>

          <Form.Item
            name="description"
            label={formatTranslation(
              t("createProtocol.objectives.objectiveDescription")
            )}
            rules={[{ required: true }]}
          >
            <TextArea rows={3} maxLength={200} />
          </Form.Item>

          <Form.Item
            name="targets"
            label={formatTranslation(
              t("createProtocol.objectives.objectiveTargets")
            )}
            rules={[
              {
                required: true,
                message: formatTranslation(
                  t("createProtocol.objectives.objectiveTargetsMessage")
                ),
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder={formatTranslation(
                t("createProtocol.objectives.targetPlaceholder")
              )}
              // optionRender={}  // write custom jsx for checkbox as per figma
            >
              {targets.map((target, index) => (
                <Option key={index} value={target.value}>
                  {target.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className="objective-drawer-footer">
            <Button onClick={handleCancel}>
              {formatTranslation(t("createProtocol.objectives.cancelButton"))}
            </Button>
            <Button
              onClick={handleAddObjective}
              // className={` ${!submittable ? "add-objective-btn-disabled" : "add-objective-btn"}`}
              className="objective-drawer-save"
              htmlType="submit"
              disabled={!submittable}
            >
              {formatTranslation(t("createProtocol.objectives.saveButton"))}
            </Button>
          </div>
        </Form>
      </Drawer>
      <div className="objectives-header">
        <div className="objectives-header-text">
          <h2>{formatTranslation(t("createProtocol.objectives.title"))}</h2>
          <p>{formatTranslation(t("createProtocol.objectives.description"))}</p>
        </div>
        <Button onClick={showDrawer} className="add-objective-btn">
          {formatTranslation(t("createProtocol.objectives.addButton"))}
        </Button>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="DropId">
          {(provided) => renderDroppable(provided)}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const ListEmptyText = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <Flex vertical align="center" justify="center" gap={"24px"}>
      <img src={emptyObj} alt="empty"></img>
      <div>
        <h1 className="text-2xl semibold">
          {formatTranslation(t("createProtocol.objectives.creteObjective"))}
        </h1>
        <div className="text-lg empty-obj-text">
          {formatTranslation(t("createProtocol.objectives.firstParagraph"))}
        </div>
      </div>
    </Flex>
  );
};

export default ObjectivesComponent;
