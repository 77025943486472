import { Collapse } from "antd";
import { type FC, useEffect } from "react";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import populateRatesDosage from "../../../pages/new-trail/hooks/PopulateRatesDosage";

import {
  type Replicants,
  type SeedsComponentProps,
} from "../../../pages/new-trail/types";
import PlotTable from "./PlotTable";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";

const RepicantsLabel = ({
  data = [],
}: {
  data: Replicants[];
}): React.ReactElement => <>{`+ ${data.length} replicants`}</>;

const PlotsComponent: FC<SeedsComponentProps> = (trialData) => {
  const {
    ratesDosage,
    newTrialState,
    setRatesDosage,
    activeKeys,
    trialProtocolData,
  } = useNewTrialState();
  const { t } = useTranslation();

  const unit =
    trialProtocolData.product_details.measure_unit ?? ratesDosage.unit_of_rate;

  useEffect(() => {
    const ratesDosageValue = populateRatesDosage(
      newTrialState.trial_plots,
      activeKeys,
      ratesDosage?.rates_and_dosages || [],
      unit,
      ratesDosage.standard_seed_rate,
      ratesDosage.standard_product
    );
    setRatesDosage(ratesDosageValue);
  }, [newTrialState.trial_plots, activeKeys]);

  useEffect(() => {
    if (
      Array.isArray(
        trialData?.trialData?.trialData?.trialData?.rates_and_dosages
      )
    ) {
      const ratesAndDosages =
        trialData?.trialData?.trialData?.trialData?.rates_and_dosages[0];
      if (ratesAndDosages) {
        const plotIds: any = [];

        newTrialState.trial_plots.forEach((trialPlot) => {
          const { rows, columns } = trialPlot.plot;

          for (let row = 0; row < rows; row++) {
            for (let col = 0; col < columns; col++) {
              plotIds.push(`${trialPlot.plot.id as number}-${row}-${col}`);
            }
          }
        });

        const updatedRatesAndDosages = ratesAndDosages?.rates_and_dosages?.map(
          (item: any, index: number) => {
            const plotId = plotIds[index] || "";

            return {
              ...item,
              plot_id: plotId,
              rate: item.rate,
              variety: item.variety,
            };
          }
        );

        setRatesDosage((prevState: any) => {
          let updatedData = prevState;
          if (!updatedData.rates_and_dosages) {
            updatedData = {
              rates_and_dosages: updatedRatesAndDosages,
              type: "seeds",
              unit_of_rate: unit || "seeds/ha",
              standard_seed_rate: ratesDosage.standard_seed_rate,
              standard_product: ratesDosage.standard_product,
            };
          }
          updatedData.rates_and_dosages = updatedRatesAndDosages;
          return updatedData;
        });
      }
    }
  }, []);

  const trialPlots = newTrialState.trial_plots;
  const ratesAndDosesData = ratesDosage?.rates_and_dosages;

  const vaildateField = (data: any): any => {
    const errors = { variety: "", range: "" };

    if (!data || ((!data.variety || data.variety === "") && data.rate !== 0)) {
      errors.variety = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.variety")
      );
    } else {
      errors.variety = "";
    }

    const selectedProduct = trialProtocolData?.product_details?.products?.find(
      (product: any) =>
        product.variety ? product.variety === data.variety : null
    );
    if (!selectedProduct) return errors;

    let range = trialProtocolData?.product_details?.global_density_range
      ? trialProtocolData?.product_details.density_range
      : selectedProduct.density_range
      ? selectedProduct.density_range
      : {};

    if (
      trialProtocolData?.product_details.measure_unit !==
      ratesDosage.unit_of_rate
    ) {
      if (ratesDosage.unit_of_rate === "seeds/m2")
        range = {
          min: range.min / 10000,
          max: range.max / 10000,
        };
      else if (ratesDosage.unit_of_rate === "seeds/ha")
        range = {
          min: range.min * 10000,
          max: range.max * 10000,
        };
    }

    if (!data.variety || data.variety === "") {
      errors.variety = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.variety")
      );
    } else {
      errors.variety = "";
    }
    if (!data.rate || data.rate === 0) {
      errors.range = formatTranslation(
        t("trials.plotDesign.trialPlots.errorText.rate")
      );
    } else if (
      !isNaN(data.rate) &&
      data.rate >= range?.min &&
      data.rate <= range?.max
    ) {
      errors.range = "";
    } else {
      errors.range = `Range for selected product is ${
        (range?.min as string) ?? "n/a"
      } to ${(range?.max as string) ?? "n/a"}`;
    }
    return errors;
  };

  const flattenedRates = ratesDosage.rates_and_dosages
    .flatMap((item) => item)
    .map((item) => {
      return {
        ...item,
        errors: vaildateField(item) || { variety: "", range: "" },
      };
    });
  return (
    <div className="plots-collapse-main">
      {trialPlots?.map((plotsItem: any, index: number) => (
        <div className="collpase-one" key={plotsItem.plot.id}>
          <Collapse
            defaultActiveKey={plotsItem.plot.id}
            className="ant-collapse-item"
            expandIconPosition="start"
          >
            <Collapse.Panel
              key={plotsItem.plot.id}
              header={
                <div className="plots-col">
                  <h4>Plot {index + 1}</h4>
                  {plotsItem?.replicants?.length ? (
                    <RepicantsLabel data={plotsItem.replicants || []} />
                  ) : (
                    <></>
                  )}
                </div>
              }
            >
              <PlotTable
                ratesAndDoses={ratesAndDosesData[index]}
                productDetails={trialProtocolData?.product_details}
                flattenedRates={flattenedRates}
                vaildateField={vaildateField}
              />
            </Collapse.Panel>
          </Collapse>
        </div>
      ))}
    </div>
  );
};

export default PlotsComponent;
