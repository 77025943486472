import { useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import type { monitorModaldata } from "../layout/types";
import AxiosInstance from "../utils/axios";
import { notification } from "antd";
import { WorkorderDownloadAPICall } from "../utils/platFormAxios";
import { useAppState } from "../../lib/appContext/AppContext";
import { useAuth } from "../../lib/auth/AuthContext";
import { downloadWorkOrder } from "../../pages/new-trail/utils";

const useDownloadWorkOrder = (): any => {
  const { stateValue } = useAppState();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const orgName = stateValue?.orgValue?.name ?? "";
  const farmName = stateValue?.farmValue?.name ?? "";
  const key = "updatable";

  const openNotification = (msg: boolean, error?: string): void => {
    notification.open({
      key,
      type: msg ? "info" : "error",
      message: msg
        ? "Downloading in progress..!"
        : "Download failed.. Please try again !",
      description: error,
      duration: msg ? 50 : 2.5,
    });
  };

  const downloadmonitorModalForm = async (
    data: monitorModaldata
  ): Promise<void> => {
    amplitude.logEvent("Trial-Steps", { buttonName: "save-and-export" });
    setIsLoading(true);
    openNotification(true);
    const baseDownloadApiUrl =
      process.env.REACT_APP_BASE_API_DOWNLOAD_MONITOR_ENDPOINT ?? "";
    try {
      const downloadApiResponse = await WorkorderDownloadAPICall.post(
        `${baseDownloadApiUrl}/shapefile?format=${
          data.selectedmonitorBrand ?? ""
        }&monitor=${data.selectedmonitorModel ?? ""}`,
        {
          geojson: data.geoJSONData,
        }
      );
      const blob = await downloadApiResponse?.data;
      downloadWorkOrder(blob);
      notification.open({
        key,
        type: "success",
        message: "Download success!",
      });
      setIsLoading(false);
    } catch (error) {
      openNotification(false, (error as Error).message);
      setIsLoading(false);
      console.error("Error While Fetching Data", error);
    }
  };

  const downloadISOXMLOrder = async (
    fielFormdData: any,
    trailId: string
  ): Promise<void> => {
    amplitude.logEvent("Trial-Steps", { buttonName: "save-and-export" });
    setIsLoading(true);
    openNotification(true);
    try {
      const downloadApiResponse = await AxiosInstance.post(
        `work_order/isoxml?trial_id=${trailId}`,
        {
          field_name: fielFormdData.name,
          organization_name: orgName,
          property_name: farmName,
          user_id: user?.id,
          field_geometry: fielFormdData.geometry,
        }
      );
      const downloadURL = downloadApiResponse?.data;
      window.location.assign(downloadURL.download_url);
      notification.open({
        key,
        type: "success",
        message: "Download success!",
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error While Fetching Data", error);
      openNotification(false, (error as Error).message);
      setIsLoading(false);
    }
  };

  return { downloadmonitorModalForm, downloadISOXMLOrder, isLoading };
};

export default useDownloadWorkOrder;
