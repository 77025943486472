import { useState, useEffect, type ReactElement } from "react";
import { Select, Form, Tooltip, Slider, Row, Col } from "antd";
import { InputNumber } from "syngenta-digital-cropwise-react-ui-kit";
import infoIcon from "../../../../assets/images/info.svg";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../shared/utils/translationUtils";
import { nutrientTypeOptions } from "../../constants";
import { round } from "lodash";
import { useCreateProtocolForm } from "../..";

const ProductsNutrientsComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { formValidationData, setFormValidationData } = useCreateProtocolForm();

  const [selectedNutrientsData, setSelectedNutrientsData] = useState({
    nutrientType: "",
    minTargetRate: 0,
    maxTargetRate: 0,
    incrementStep: 0,
    numberOfRateLevels: 1,
  });
  const [shouldDisplayErrorChecks, setShouldDisplayErrorChecks] = useState({
    nutrientType: !formValidationData.isNewProtocol,
    minRateLimit: {
      minTarget: !formValidationData.isNewProtocol,
      maxTarget: !formValidationData.isNewProtocol,
      incrementStep: !formValidationData.isNewProtocol,
    },
    minLowerThanMaxRate: false,
    maxHigherThanMinRate: false,
    tooLargeRate: false,
    noOfLevels: false,
  });

  useEffect(() => {
    // To enable/disable summary button based on validations
    const commonCheck =
      shouldDisplayErrorChecks.nutrientType ||
      shouldDisplayErrorChecks.minRateLimit.minTarget ||
      shouldDisplayErrorChecks.minRateLimit.maxTarget ||
      shouldDisplayErrorChecks.minRateLimit.incrementStep ||
      shouldDisplayErrorChecks.minLowerThanMaxRate ||
      shouldDisplayErrorChecks.maxHigherThanMinRate ||
      shouldDisplayErrorChecks.tooLargeRate ||
      shouldDisplayErrorChecks.noOfLevels;

    setFormValidationData({
      ...formValidationData,
      nutrients: selectedNutrientsData,
      isNutrientDataValid: !commonCheck,
    });
  }, [shouldDisplayErrorChecks]);

  const maxNumberOfLevels = round(
    (selectedNutrientsData?.maxTargetRate -
      selectedNutrientsData?.minTargetRate) /
      selectedNutrientsData?.incrementStep +
      1
  );

  const activateRateIntervalsSection =
    shouldDisplayErrorChecks.minRateLimit.minTarget ||
    shouldDisplayErrorChecks.minLowerThanMaxRate ||
    shouldDisplayErrorChecks.minRateLimit.maxTarget ||
    shouldDisplayErrorChecks.maxHigherThanMinRate;

  const checkNumberType = (number: any): number => {
    if (typeof number !== "number") return 0;
    else return number;
  };

  const handleNutrientTypeChange = (nutrientType: string): any => {
    if (nutrientType)
      setShouldDisplayErrorChecks({
        ...shouldDisplayErrorChecks,
        nutrientType: false,
      });
    setSelectedNutrientsData({ ...selectedNutrientsData, nutrientType });
  };

  const handleMinTargetRateChange = (minTargetRate: number): any => {
    if (minTargetRate != null) {
      const modifiedChecks = {
        nutrientType: shouldDisplayErrorChecks.nutrientType,
        minRateLimit: {
          minTarget: !(minTargetRate >= 1),
          maxTarget: shouldDisplayErrorChecks.minRateLimit.maxTarget,
          incrementStep: shouldDisplayErrorChecks.minRateLimit.incrementStep,
        },
        minLowerThanMaxRate:
          minTargetRate > selectedNutrientsData?.maxTargetRate,
        maxHigherThanMinRate: !(
          selectedNutrientsData?.maxTargetRate > minTargetRate
        ),
        tooLargeRate: !(
          selectedNutrientsData?.incrementStep <=
          selectedNutrientsData?.maxTargetRate - minTargetRate
        ),
        noOfLevels: shouldDisplayErrorChecks.noOfLevels,
      };
      setShouldDisplayErrorChecks(modifiedChecks);

      setSelectedNutrientsData({
        ...selectedNutrientsData,
        minTargetRate: checkNumberType(minTargetRate),
      });
    }
  };

  const handleMaxTargetRateChange = (maxTargetRate: number): any => {
    if (maxTargetRate != null) {
      const modifiedChecks = {
        nutrientType: shouldDisplayErrorChecks.nutrientType,
        minRateLimit: {
          minTarget: shouldDisplayErrorChecks.minRateLimit.minTarget,
          maxTarget: !(maxTargetRate >= 1),
          incrementStep: shouldDisplayErrorChecks.minRateLimit.incrementStep,
        },
        minLowerThanMaxRate:
          maxTargetRate < selectedNutrientsData?.minTargetRate,
        maxHigherThanMinRate:
          maxTargetRate < selectedNutrientsData?.minTargetRate,
        tooLargeRate: !(
          selectedNutrientsData?.incrementStep <=
          maxTargetRate - selectedNutrientsData?.minTargetRate
        ),
        noOfLevels: shouldDisplayErrorChecks.noOfLevels,
      };
      setShouldDisplayErrorChecks(modifiedChecks);

      setSelectedNutrientsData({
        ...selectedNutrientsData,
        maxTargetRate: checkNumberType(maxTargetRate),
      });
    }
  };

  const handleIncrementStepChange = (incrementStep: number): any => {
    if (incrementStep != null) {
      const modifiedChecks = {
        nutrientType: shouldDisplayErrorChecks.nutrientType,
        minRateLimit: {
          minTarget: shouldDisplayErrorChecks.minRateLimit.minTarget,
          maxTarget: shouldDisplayErrorChecks.minRateLimit.maxTarget,
          incrementStep: !(incrementStep >= 1),
        },
        minLowerThanMaxRate: shouldDisplayErrorChecks.minLowerThanMaxRate,
        maxHigherThanMinRate: shouldDisplayErrorChecks.maxHigherThanMinRate,
        tooLargeRate: !(
          incrementStep <=
          selectedNutrientsData?.maxTargetRate -
            selectedNutrientsData?.minTargetRate
        ),
        noOfLevels: shouldDisplayErrorChecks.noOfLevels,
      };
      setShouldDisplayErrorChecks(modifiedChecks);

      setSelectedNutrientsData({
        ...selectedNutrientsData,
        incrementStep: checkNumberType(incrementStep),
      });
    }
  };
  const handleNumberOfRateLevelChange = (numberOfRateLevels: number): any => {
    if (numberOfRateLevels != null) {
      const modifiedChecks = {
        nutrientType: shouldDisplayErrorChecks.nutrientType,
        minRateLimit: shouldDisplayErrorChecks.minRateLimit,
        minLowerThanMaxRate: shouldDisplayErrorChecks.minLowerThanMaxRate,
        maxHigherThanMinRate: shouldDisplayErrorChecks.maxHigherThanMinRate,
        tooLargeRate: shouldDisplayErrorChecks.tooLargeRate,
        noOfLevels: !(numberOfRateLevels <= maxNumberOfLevels),
      };
      setShouldDisplayErrorChecks(modifiedChecks);

      setSelectedNutrientsData({
        ...selectedNutrientsData,
        numberOfRateLevels: checkNumberType(numberOfRateLevels),
      });
    }
  };

  return (
    <div className="products-nutrients-component">
      <div>
        <h2 className="nutrients-header">
          {formatTranslation(t("createProtocol.products.nutrients.header"))}
        </h2>
        <p className="nutrient-description">
          {formatTranslation(
            t("createProtocol.products.nutrients.description")
          )}
        </p>
      </div>
      <Form
        className={`nutrient-type ${
          !shouldDisplayErrorChecks.nutrientType ? "extra-bottom-margin" : ""
        }`}
      >
        <Form.Item
          label={formatTranslation(t("createProtocol.products.nutrients.type"))}
          className="nutrient-type-label"
        >
          <Select
            placeholder={formatTranslation(
              t("createProtocol.products.nutrients.chooseNutrient")
            )}
            value={
              selectedNutrientsData?.nutrientType === ""
                ? null
                : selectedNutrientsData?.nutrientType
            }
            onChange={(nutrientType: string): void => {
              handleNutrientTypeChange(nutrientType);
            }}
            options={nutrientTypeOptions}
            status={
              !formValidationData.isNewProtocol &&
              selectedNutrientsData?.nutrientType === ""
                ? "error"
                : ""
            }
            size="large"
          />
          {shouldDisplayErrorChecks.nutrientType && (
            <span className="error-message">
              {formatTranslation(
                t("createProtocol.products.nutrients.nutrientType.error")
              )}
            </span>
          )}
        </Form.Item>
      </Form>

      <div
        className={`target-app-rates-outer-div ${
          activateRateIntervalsSection ? "extra-bottom-margin" : ""
        }`}
      >
        <div className="target-app-rates">
          <h3 className="target-app-rates-header">
            {formatTranslation(
              t("createProtocol.products.nutrients.targetAppRates")
            )}
          </h3>
          <Tooltip
            placement="right"
            title={formatTranslation(
              t("createProtocol.products.nutrients.tooltip.targetAppRates")
            )}
          >
            <img src={infoIcon} alt="info-icon" className="info-icon" />
          </Tooltip>
        </div>
        <Form className="min-max-rates-inputs">
          <Form.Item label="Min Rate %" className="input-rate-label">
            <InputNumber
              type="number"
              min={1}
              value={selectedNutrientsData?.minTargetRate}
              onChange={(minValue: any): void => {
                handleMinTargetRateChange(minValue);
              }}
              className="input-number"
              status={
                !formValidationData.isNewProtocol &&
                (shouldDisplayErrorChecks.minRateLimit.minTarget ||
                  shouldDisplayErrorChecks.minLowerThanMaxRate)
                  ? "error"
                  : ""
              }
              size="large"
            />
            {shouldDisplayErrorChecks.minRateLimit.minTarget && (
              <span className="error-message">
                {formatTranslation(
                  t("createProtocol.products.nutrients.minRateLimit.error")
                )}
              </span>
            )}
            {shouldDisplayErrorChecks.minLowerThanMaxRate && (
              <span className="error-message">
                {formatTranslation(
                  t(
                    "createProtocol.products.nutrients.minLowerThanMaxRate.error"
                  )
                )}
              </span>
            )}
          </Form.Item>
          <Form.Item label="Max Rate %" className="input-rate-label">
            <InputNumber
              type="number"
              min={1}
              value={selectedNutrientsData?.maxTargetRate}
              onChange={(maxValue: any): void => {
                handleMaxTargetRateChange(maxValue);
              }}
              className="input-number"
              status={
                (!formValidationData.isNewProtocol &&
                  shouldDisplayErrorChecks.minRateLimit.maxTarget) ||
                shouldDisplayErrorChecks.maxHigherThanMinRate
                  ? "error"
                  : ""
              }
              size="large"
            />
            {shouldDisplayErrorChecks.minRateLimit.maxTarget && (
              <span className="error-message">
                {formatTranslation(
                  t("createProtocol.products.nutrients.minRateLimit.error")
                )}
              </span>
            )}
            {shouldDisplayErrorChecks.maxHigherThanMinRate && (
              <span className="error-message">
                {formatTranslation(
                  t(
                    "createProtocol.products.nutrients.maxHigherThanMinRate.error"
                  )
                )}
              </span>
            )}
          </Form.Item>
        </Form>
      </div>
      <div
        className={`rate-intervals ${
          !activateRateIntervalsSection &&
          (!shouldDisplayErrorChecks.minRateLimit.incrementStep ||
            !shouldDisplayErrorChecks.tooLargeRate)
            ? "extra-bottom-margin"
            : ""
        }`}
      >
        <div className="rate-intervals-header">
          <h3>
            {formatTranslation(
              t("createProtocol.products.nutrients.rateIntervals")
            )}
          </h3>
          <Tooltip
            placement="right"
            title={formatTranslation(
              t("createProtocol.products.nutrients.tooltip.rateIntervals")
            )}
          >
            <img src={infoIcon} alt="info-icon" className="info-icon" />
          </Tooltip>
        </div>
        <Form className="rate-interval-fields">
          <Form.Item
            className="increment-steps-label"
            label={formatTranslation(
              t("createProtocol.products.nutrients.incrementSteps")
            )}
          >
            <InputNumber
              type="number"
              min={1}
              value={selectedNutrientsData?.incrementStep}
              onChange={(increment: any): void => {
                handleIncrementStepChange(increment);
              }}
              className="input-number"
              disabled={activateRateIntervalsSection}
              status={
                (!formValidationData.isNewProtocol &&
                  shouldDisplayErrorChecks.minRateLimit.incrementStep) ||
                shouldDisplayErrorChecks.tooLargeRate
                  ? "error"
                  : ""
              }
              size="large"
            />
            {!activateRateIntervalsSection &&
              shouldDisplayErrorChecks.minRateLimit.incrementStep && (
                <span className="error-message">
                  {formatTranslation(
                    t("createProtocol.products.nutrients.minRateLimit.error")
                  )}
                </span>
              )}
            {!activateRateIntervalsSection &&
              shouldDisplayErrorChecks.tooLargeRate && (
                <span className="error-message">
                  {formatTranslation(
                    t("createProtocol.products.nutrients.tooLargeRate.error")
                  )}
                </span>
              )}
          </Form.Item>
          <Form.Item
            className="rate-level-slider"
            label={formatTranslation(
              t("createProtocol.products.nutrients.rateLevels")
            )}
          >
            <Row className="rate-level-slider-row">
              <Col span={15}>
                <Slider
                  className="slider"
                  min={1}
                  max={maxNumberOfLevels}
                  tooltip={{ open: false }}
                  value={selectedNutrientsData?.numberOfRateLevels}
                  onChange={(numberOfRateLevels: any): void => {
                    handleNumberOfRateLevelChange(numberOfRateLevels);
                  }}
                  disabled={
                    selectedNutrientsData?.incrementStep < 1 ||
                    shouldDisplayErrorChecks.minRateLimit.incrementStep ||
                    shouldDisplayErrorChecks.tooLargeRate
                  }
                  defaultValue={0}
                  marks={
                    checkNumberType(maxNumberOfLevels) &&
                    maxNumberOfLevels !== Infinity &&
                    maxNumberOfLevels !== -Infinity &&
                    maxNumberOfLevels > 0
                      ? {
                          1: {
                            style: {
                              color: "#A3A9B9",
                            },
                            label: 1,
                          },
                          [maxNumberOfLevels]: {
                            style: {
                              color: "#A3A9B9",
                            },
                            label: maxNumberOfLevels,
                          },
                        }
                      : {}
                  }
                />
              </Col>
              <Col span={1}>
                <InputNumber
                  min={1}
                  className={`rate-level-input ${
                    selectedNutrientsData?.incrementStep < 1 ||
                    shouldDisplayErrorChecks.minRateLimit.incrementStep ||
                    shouldDisplayErrorChecks.tooLargeRate
                      ? "disabled-rate-level-input"
                      : ""
                  }`}
                  value={selectedNutrientsData?.numberOfRateLevels}
                  onChange={(numberOfRateLevels: any): void => {
                    handleNumberOfRateLevelChange(numberOfRateLevels);
                  }}
                  disabled={
                    selectedNutrientsData?.incrementStep < 1 ||
                    shouldDisplayErrorChecks.minRateLimit.incrementStep ||
                    shouldDisplayErrorChecks.tooLargeRate
                  }
                  status={
                    !formValidationData.isNewProtocol &&
                    shouldDisplayErrorChecks.noOfLevels
                      ? "error"
                      : undefined
                  }
                />
              </Col>
            </Row>
            {shouldDisplayErrorChecks.noOfLevels && (
              <span className="error-message">
                {formatTranslation(
                  t("createProtocol.products.nutrients.noOfLevels.error")
                )}
              </span>
            )}
          </Form.Item>
        </Form>
      </div>

      <p className="disclaimer">
        &quot;I expect the{" "}
        <span className="disclaimer-blue">
          {selectedNutrientsData?.nutrientType === ""
            ? "_"
            : selectedNutrientsData?.nutrientType}
        </span>{" "}
        application, based on the grower&apos;s standard rate, to range from{" "}
        <span className="disclaimer-blue">
          {selectedNutrientsData?.minTargetRate === 0
            ? "_"
            : `${selectedNutrientsData?.minTargetRate}%`}
        </span>{" "}
        to{" "}
        <span className="disclaimer-blue">
          {selectedNutrientsData?.maxTargetRate === 0
            ? "_"
            : `${selectedNutrientsData?.maxTargetRate}%`}
        </span>
        , in increments of at least{" "}
        <span className="disclaimer-blue">
          {selectedNutrientsData?.incrementStep === 0
            ? "_"
            : `${selectedNutrientsData?.incrementStep}%`}
        </span>
        , and I expect at least{" "}
        <span className="disclaimer-blue">
          {selectedNutrientsData?.numberOfRateLevels === 0
            ? "_"
            : selectedNutrientsData?.numberOfRateLevels}
        </span>{" "}
        distinct application rates.&quot;
      </p>
    </div>
  );
};
export default ProductsNutrientsComponent;
