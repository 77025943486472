import { Select } from "antd";
import { type FC, } from "react";
import PlotsComponent from "../../../../shared/layout/Plots";
import { useNewTrialState } from "../../hooks/NewTrialStateProvider";
import { type SeedsComponentProps } from "../../types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../shared/utils/translationUtils";
import StandardSeedRate from "../../../../shared/layout/StandardSeedRate";

export const RateUnitDropDown = (): React.ReactElement => {
  const { t } = useTranslation();
  const { setRatesDosage, ratesDosage } = useNewTrialState();
  const handleSelection = (value: string): void => {
    const updatedRatesDosage = {
      ...ratesDosage,
      unit_of_rate: value,
    };
    setRatesDosage(updatedRatesDosage);
  };

  return (
    <div className="rating-dropdown-container">
      <div className="rates-select">
        <h3 className="rates-title">
          {formatTranslation(t("newTrial.ratesDosage.rates"))}
        </h3>
        <Select
          value={ratesDosage?.unit_of_rate || "seeds/ha"}
          onChange={(value) => {
            handleSelection(value);
          }}
        >
          <Select.Option value="seeds/ha">
            {formatTranslation(t("newTrial.ratesDosage.unit1"))}
          </Select.Option>
          <Select.Option value="seeds/m2">
            {formatTranslation(t("newTrial.ratesDosage.unit2"))}
          </Select.Option>
        </Select>
      </div>
    </div>
  );
};

const SeedsComponent: FC<SeedsComponentProps> = (trialData) => {
  return (
    <div className="rates-dosage">
      <StandardSeedRate />
      <PlotsComponent trialData={trialData} />
    </div>
  );
};

export default SeedsComponent;
