import { type FC } from "react";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { Tooltip, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { type DeleteIconProps } from "../types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import deleteDiseableIcon from "../../../assets/images/delete-disabled.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import "./deleteButton.less";

export const DeleteButton: FC<DeleteIconProps> = ({
  onClick,
  children,
  protocolName,
  disabled,
}) => {
  const { t } = useTranslation();
  let tootTipText;

  disabled === true
    ? (tootTipText = (
        <span>
          {formatTranslation(t("protocols.deleteDisableButtonTooltipText"))}
        </span>
      ))
    : (tootTipText = (
        <span>{formatTranslation(t("protocols.deleteButtonTooltipText"))}</span>
      ));

  return (
    <Tooltip data-testid="tooltip-text" placement="left" title={tootTipText}>
      <Popconfirm
        rootClassName="protocol-delete-popup"
        placement="topRight"
        title={`Delete the ${protocolName as string}?`}
        description={
          <span className="del-label">
            {formatTranslation(t("trials.deleteText"))}
          </span>
        }
        icon={<QuestionCircleOutlined />}
        onConfirm={onClick}
      >
        <Button
          className={disabled ? "delete-button-disabled" : "delete-button"}
          disabled={disabled}
        >
          {disabled ? (
            <img src={deleteDiseableIcon} alt="delete-icon-disabled" />
          ) : (
            <img src={deleteIcon} alt="delete-icon" />
          )}
          {children}
        </Button>
      </Popconfirm>
    </Tooltip>
  );
};
