import { Popover } from "antd";
import { useEffect, useState, type FC } from "react";
import { useNavigate } from "react-router-dom";
import downArrowIcon from "../../../../assets/images/arrow-down.svg";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { useAppState } from "../../../../lib/appContext/AppContext";
import useAxios from "../../../custom-hooks/useAxios";
import { getInitials } from "../utils";
import { route } from "../../../../pages/constant";

export const FarmSelect: FC = () => {
  const [open, setOpen] = useState<boolean>();
  const navigate = useNavigate();
  const { stateValue, setStateValue } = useAppState();
  const { response: farmResponse } = useAxios(
    `/${route.ORGS.path}/${stateValue?.orgValue?.id as string}/properties`,
    true
  );

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };

  const handleSelectValue = (val: any): void => {
    setStateValue({
      ...stateValue,
      farmValue: val,
    });
    setOpen(false);
    navigate(`/app/${route.TRIALS.path}`);
  };

  const handleNavFarmes = (): void => {
    navigate(`/app/${route.FARMS.path}`);
    setOpen(false);
    setStateValue({
      ...stateValue,
      farmValue: null,
    });
  };

  useEffect(() => {
    if (stateValue?.orgValue) {
      setStateValue({
        ...stateValue,
        farmValue: null,
      });
    }
  }, [stateValue?.orgValue]);

  const content = (
    <div>
      <div className="upper-content">
        <div className="select-values">
          {farmResponse?.content.map((val: any, i: number) => (
            <Button
              key={`${val.name as string}-${i}`}
              onClick={() => {
                handleSelectValue(val);
              }}
            >
              <div className="splits">{getInitials(val.name)}</div>
              {val.name}
            </Button>
          ))}
        </div>
      </div>
      <div className="goto-org">
        <Button onClick={handleNavFarmes}>Go to farms</Button>
      </div>
    </div>
  );
  return (
    stateValue?.orgValue && (
      <div className="org-select-dropdown-main">
        <Popover
          content={content}
          title="Farm"
          trigger="click"
          arrow={false}
          rootClassName="org-select-dropdown"
          placement="bottomLeft"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <button className="select-dropdown">
            <h2>Farm</h2>
            <div className="start-select">
              <h4>
                {stateValue?.farmValue === null
                  ? "All farms"
                  : stateValue?.farmValue?.name}
              </h4>
              <img src={downArrowIcon} alt="downArrowIcon" />
            </div>
          </button>
        </Popover>
      </div>
    )
  );
};
